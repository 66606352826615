export const TwitterIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7587 0.394423C19.4253 -0.00424693 18.8587 -0.103914 18.4253 0.195088L16.492 1.52399C15.592 0.560536 14.3253 0.0289756 12.9587 0.0289756C10.2587 0.0289756 8.02532 2.22166 8.02532 4.94591C8.02532 5.34458 8.05865 5.71002 8.15865 6.07547C5.82532 5.57713 4.42532 3.88279 3.89198 0.826316C3.82532 0.427646 3.52532 0.128643 3.12532 0.0289756C2.75865 -0.070692 2.35865 0.0954206 2.12532 0.394423C2.05865 0.460868 -3.44135 8.10205 4.52532 14.5804C3.75865 16.2083 1.45865 17.6037 0.558651 18.0688C0.125318 18.2681 -0.0746825 18.7332 0.0253175 19.1984C0.125318 19.6635 0.525318 19.9625 0.991984 19.9625H2.72532C12.8587 19.9625 17.4587 14.9459 17.5587 3.71667L19.6587 1.72332C20.0587 1.3911 20.092 0.793093 19.7587 0.394423Z" fill="#505F79" />
  </svg>
);

export const TwitterXIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="100px" height="100px">
    <g fill="#505f79" fillRule="nonzero">
      <path d="M5.91992,6l14.66211,21.375l-14.35156,16.625h3.17969l12.57617,-14.57812l10,14.57813h12.01367l-15.31836,-22.33008l13.51758,-15.66992h-3.16992l-11.75391,13.61719l-9.3418,-13.61719zM9.7168,8h7.16406l23.32227,34h-7.16406z" />
    </g>
  </svg>
);

export const BurgerMenuIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 15C11 14.4477 11.4477 14 12 14H28C28.5523 14 29 14.4477 29 15C29 15.5523 28.5523 16 28 16H12C11.4477 16 11 15.5523 11 15ZM11 20C11 19.4477 11.4477 19 12 19L28 19C28.5523 19 29 19.4477 29 20C29 20.5523 28.5523 21 28 21L12 21C11.4477 21 11 20.5523 11 20ZM12 24C11.4477 24 11 24.4477 11 25C11 25.5523 11.4477 26 12 26H28C28.5523 26 29 25.5523 29 25C29 24.4477 28.5523 24 28 24H12Z" fill="#6070ff" />
  </svg>
);

export const CloseMenuIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2929 13.2929C13.6834 12.9024 14.3166 12.9024 14.7071 13.2929L20 18.5858L25.2929 13.2929C25.6834 12.9024 26.3166 12.9024 26.7071 13.2929C27.0976 13.6834 27.0976 14.3166 26.7071 14.7071L21.4142 20L26.7071 25.2929C27.0976 25.6834 27.0976 26.3166 26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071L20 21.4142L14.7071 26.7071C14.3166 27.0976 13.6834 27.0976 13.2929 26.7071C12.9024 26.3166 12.9024 25.6834 13.2929 25.2929L18.5858 20L13.2929 14.7071C12.9024 14.3166 12.9024 13.6834 13.2929 13.2929Z" fill="#6070ff" />
  </svg>
);

export const LinkedinIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6448 3.03455C10.1561 3.03455 7.28906 5.86213 7.28906 9.37937V19.3104C7.28906 19.6897 7.59994 20.0001 7.97991 20.0001H11.5723C11.9523 20.0001 12.2632 19.6897 12.2632 19.3104V9.65524C12.2632 8.89661 12.8849 8.27592 13.6448 8.27592C14.4048 8.27592 15.0265 8.89661 15.0265 9.65524V19.3104C15.0265 19.6897 15.3374 20.0001 15.7174 20.0001H19.3098C19.6898 20.0001 20.0006 19.6897 20.0006 19.3104V9.37937C20.0006 5.89662 17.1682 3.03455 13.6448 3.03455Z" fill="#505F79" />
    <path d="M2.48705 0C1.10535 0 0 1.10345 0 2.48276C0 3.86207 1.10535 4.96552 2.48705 4.96552C3.86874 4.96552 4.97409 3.86207 4.97409 2.48276C4.97409 1.10345 3.86874 0 2.48705 0Z" fill="#505F79" />
    <path d="M4.28325 6.27588H0.690846C0.310881 6.27588 0 6.58622 0 6.96553V19.3104C0 19.6897 0.310881 20 0.690846 20H4.28325C4.66321 20 4.97409 19.6897 4.97409 19.3104V6.96553C4.97409 6.58622 4.66321 6.27588 4.28325 6.27588Z" fill="#505F79" />
  </svg>
);

export const MediumIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4042 1.88042L20 0.337684V0H14.4717L10.5317 9.90989L6.04917 0H0.2525V0.337684L2.11667 2.60547C2.29833 2.77305 2.39333 3.01811 2.36917 3.26484V12.1768C2.42667 12.4977 2.32333 12.8278 2.1 13.0611L0 15.6328V15.9663H5.95417V15.6286L3.85417 13.0611C3.62667 12.8269 3.51917 12.5027 3.565 12.1768V4.46821L8.79167 15.9705H9.39917L13.8933 4.46821V13.6312C13.8933 13.8728 13.8933 13.9225 13.7367 14.0808L12.12 15.6615V16H19.9633V15.6623L18.405 14.1204C18.2683 14.016 18.1975 13.8408 18.2267 13.6707V2.33011C18.1975 2.15916 18.2675 1.984 18.4042 1.88042Z" fill="#505F79" />
  </svg>
);

export const GithubIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9852 7.46114C17.9852 6.32124 17.6192 5.25043 16.9205 4.31779C17.1867 3.41969 17.2532 2.27979 17.1867 0.967185C17.1534 0.414508 16.7209 0 16.1885 0C15.8891 0 13.3604 0.0345423 11.8965 1.38169C10.6321 1.1399 9.30128 1.1399 8.00368 1.38169C6.573 0.0345423 4.04435 0 3.71163 0C3.17928 0 2.74675 0.414508 2.71348 0.967185C2.61366 2.27979 2.71348 3.41969 2.97965 4.31779C2.28095 5.28497 1.91496 6.35579 1.91496 7.46114C1.91496 9.8791 3.71163 12.0553 6.43991 13.0915C6.3401 13.2988 6.27355 13.5406 6.20701 13.7824C3.31237 13.4715 1.94823 10.7427 1.88169 10.639C1.64878 10.1209 1.04989 9.91364 0.550818 10.19C0.0517425 10.4318 -0.147888 11.0535 0.118286 11.5717C0.184829 11.7444 2.01477 15.4404 6.04065 15.8549V18.9637C6.04065 19.5509 6.47319 20 7.0388 20H12.8614C13.427 20 13.8595 19.5509 13.8595 18.9637V14.8532C13.8595 14.2314 13.7264 13.6442 13.4935 13.1261C16.1885 12.0553 17.9852 9.91364 17.9852 7.46114Z" fill="#505F79" />
  </svg>
);

export const CircleIcon = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" fill="#C1C7D0" />
  </svg>
);

export const RightArrowIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 5.43962C9.68342 5.0491 10.3166 5.0491 10.7071 5.43962L16.7071 11.4396C17.0976 11.8301 17.0976 12.4633 16.7071 12.8538L10.7071 18.8538C10.3166 19.2444 9.68342 19.2444 9.29289 18.8538C8.90237 18.4633 8.90237 17.8301 9.29289 17.4396L14.5858 12.1467L9.29289 6.85384C8.90237 6.46331 8.90237 5.83015 9.29289 5.43962Z" fill="#505F79" />
  </svg>
);

export const DownArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#5E6C84" />
  </svg>
);

export const CloseDetailsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#67798E" />
  </svg>
);

export const LiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 12C5 8.13401 8.13401 5 12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 11.4477 20.5523 11 20 11C19.4477 11 19 11.4477 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12ZM16 3C15.4477 3 15 3.44772 15 4C15 4.55228 15.4477 5 16 5H17.5858L11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L19 6.41421V8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8V4C21 3.44772 20.5523 3 20 3H16Z" fill="#6070FF" />
  </svg>
);

export const SourceIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_39_522)">
      <g clipPath="url(#clip1_39_522)">
        <path d="M20.9989 9.46114C20.9989 8.32124 20.6329 7.25043 19.9342 6.31779C20.2004 5.41969 20.2669 4.27979 20.2004 2.96718C20.1671 2.41451 19.7345 2 19.2022 2C18.9028 2 16.3741 2.03454 14.9101 3.38169C13.6458 3.1399 12.315 3.1399 11.0174 3.38169C9.58667 2.03454 7.05802 2 6.7253 2C6.19296 2 5.76042 2.41451 5.72715 2.96718C5.62734 4.27979 5.72715 5.41969 5.99333 6.31779C5.29462 7.28497 4.92863 8.35579 4.92863 9.46114C4.92863 11.8791 6.7253 14.0553 9.45358 15.0915C9.35377 15.2988 9.28723 15.5406 9.22068 15.7824C6.32604 15.4715 4.9619 12.7427 4.89536 12.639C4.66246 12.1209 4.06357 11.9136 3.56449 12.19C3.06541 12.4318 2.86578 13.0535 3.13196 13.5717C3.1985 13.7444 5.02845 17.4404 9.05432 17.8549V20.9637C9.05432 21.5509 9.48686 22 10.0525 22H15.875C16.4406 22 16.8732 21.5509 16.8732 20.9637V16.8532C16.8732 16.2314 16.7401 15.6442 16.5072 15.1261C19.2022 14.0553 20.9989 11.9136 20.9989 9.46114Z" fill="#6070FF" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_39_522">
        <rect width="18" height="20" fill="white" transform="translate(3 2)" />
      </clipPath>
      <clipPath id="clip1_39_522">
        <rect width="18" height="20" fill="white" transform="translate(3 2)" />
      </clipPath>
    </defs>
  </svg>

);
